import React, { Component } from "react";
import Slider from "react-slick";
import CardComplementa from "./card_complementa";

export default class CarouselCardComplementa extends Component {

  cards(datos) {
    let render = []
    for (let index = 0; index < datos.length; index++) {


      render.push(<div className="witCard" >
        <CardComplementa data={datos} id={index} />

      </div>);
    }
    return render;
  }

  render() {




    console.log(this.props.data)
    let datos = this.props.data
    const settings = {
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      speed: 500,
      dots: true,
      className: "slider variable-width",
      responsive: [
        {
          breakpoint: 1090,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            centerMode: true,
          }
        },
        {
          breakpoint: 940,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '80px'
          }
        },
        {
          breakpoint: 730,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,

          }
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
    return (
      <div className={'my-5 anchoComplementa'}>
        <div className={'text-center mx-3 mb-5 mx-lg-0'}>
          <h3 className="tituloInteres">Complementa tu visita</h3>
          <p className="labelNormal">Pensando en que tu visita sea la mejor experiencia, ponemos a tu disposición productos que te ayuden a disfrutar del parque sin límites.</p>
        </div>
        <Slider {...settings}>
          {/*<div className="witCard" >
            <CardComplementa data={datos} id={0}/>
            {cards(datos)}
          </div>
          <div className="witCard" >
          <CardComplementa data={datos} id={1}/>
          </div>
          <div className="witCard" >
          <CardComplementa data={datos} id={2}/>
          </div>
          <div className="witCard">
          <CardComplementa data={datos} id={3}/>
          </div>
          <div className="witCard">
          <CardComplementa data={datos} id={4}/>
          </div>
          <div className="witCard">
          <CardComplementa data={datos} id={5}/>
    </div>*/}
          {this.cards(datos)}
        </Slider>
      </div>
    );
  }
}